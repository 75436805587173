<template>
  <div :class="tv ? 'tv-card-w min-w-350' : 'w-80' " class="h-card overflow-ellipsis overflow-hidden pt-6 hover:bg-redDamask active:bg-redDamask hover:bg-opacity-10 active:bg-opacity-10 rounded-lg m-8 relative">
    <a :href="postArray.link" class="cursor-pointer relative">
      <div v-if="tv">
        <span class="absolute top-40 left-2 font-heading text-8xl text-transparent bg-clip-text bg-gradient-to-b from-halfColonialWhite to-halfColonialWhite-transparent tracking-tighter min-w-9">{{ newIndex(itemIndex) }}</span>
      </div>
      <div :class="content.scratch_game ? 'border-0 text-center w-44' : 'p-1 border-4 border-sepiaBlack w-36' " class="h-56 mx-auto rounded-t-full rounded-b relative overflow-hidden" style=' content.scratch_game ? " " : "box-shadow: 0px 0px 0px 1px #c39e5c;"'>
        <img v-if="!content.scratch_game" :src="postArray.src" :alt="postArray.alt" class="mx-auto h-full overflow-hidden max-w-max	absolute left-1/2 top-0 transform -translate-x-1/2">
        <img v-if="content.scratch_game" :src="src"/>
        <Button v-if="content.scratch_game" :text="$t('scratch.btnText')" buttonType="game" />
      </div>
      
      <div class="px-4 py-3 text-center">
        <div v-if="size == 'medium'">
          <h4 class="font-heading text-h4 text-sepiaBlack dark:text-halfColonialWhite overflow-ellipsis overflow-hidden max-h-20">{{ postArray.title }}</h4>
        </div>
        <div v-else>
          <h5 class="font-heading text-h5 text-sepiaBlack dark:text-halfColonialWhite overflow-ellipsis overflow-hidden max-h-20">{{ postArray.title }}</h5>
        </div>
        <p class="font-body text-bodyS text-sepiaBlack dark:text-halfColonialWhite pt-4 overflow-ellipsis overflow-hidden max-h-12">{{ postArray.text }}</p>
      </div>
    </a>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  name: 'EntityList',
  components: {
    Button
  },
  props: {
    size: {
      type: String,
      require: false,
      default: 'medium'
    },
    content: {
      type: Object,
      require: true,
      default: () => {}
    },
    tv: {
      type: Boolean,
      require: false,
      default: false
    },
    itemIndex: {
      type: Number,
      require: false
    }
  },
  computed: {
    postArray() {
      return {
        title: this.content?.title,
        text: this.content?.sport_content,
        src: this.content?.img,
        alt: this.content?.alt,
        link: window.location.pathname.slice(0, -1) + '/' + this.content?.id + (this.content?.scratch_game ? '/game' : ''),
        id: this.content?.id
      }
    },
    src() {
      //TODO change home incon to scrach game icon
      return require("../../assets/scratchDescription.png");
    },
  },
  methods: {
    newIndex: function(value) {
      if(value >= 10) {
        return value + 1;
      } 
      return `0${value+1}`
    }
  }
}
</script>
