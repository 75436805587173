<template>
    <span v-if="pagination.prevPage || pagination.nextPage" class="flex h-10 rounded-full bg-halfColonialWhite max-w-min border border-halfColonialWhite cursor-pointer">
        <button v-if="pagination.prevPage" @click="$emit('prev')" class="flex items-center content-center w-28 rounded-full py-3 px-1 hover:bg-dutchWhite active:bg-dutchWhite focus:bg-dutchWhite">
            <BaseIcon name="arrowLeft" outline = false />
            <p class="font-body text-bodyM" id="darkText">{{ $t('entity.prev') }}</p>
        </button>
        <button v-if="pagination.nextPage" @click="$emit('next')" class="flex items-center content-center w-28 rounded-full py-3 px-1 hover:bg-dutchWhite active:bg-dutchWhite focus:bg-dutchWhite">
            <p class="font-body text-bodyM pl-2">{{ $t('entity.next') }}</p>
            <BaseIcon name="arrowRight" outline = false />
        </button>
    </span>
</template>

<script>
    import BaseIcon from '@/components/BaseIcon.vue';

    export default {
        name: 'Pagination',
        components: {
            BaseIcon
        },
        props: ['pagination'],
        mounted() {
            this.$i18n.locale = this.lang;
        },
    }
</script> 

<!--
<template>
    <nav class="pagination">
        <span class="page-stats">{{pagination.from}} - {{pagination.to}} of {{pagination.total}}</span>
        <a v-if="pagination.prevPage" @click="$emit('prev')" class="button is-small pagination-previous">
            Prev
        </a>
        <a class="button is-small pagination-previous" v-else disabled>
            Prev
        </a>

        <a v-if="pagination.nextPage" @click="$emit('next')" class="button is-small pagination-next">
            Next
        </a>
        <a class="button is-small pagination-next" v-else disabled>
            Next
        </a>
    </nav>
</template>

<script>
    export default {
        props: ['pagination'],
    }
</script> -->